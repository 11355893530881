<template>
  <div>
    <loading-overlay :active="loading == 1 || ($store.state.yardManagement.loading && this.$store.state.yardManagement.yardCollapse == 2)" :is-full-page="true" loader="bars"/>
    <CCollapse :show="$store.state.yardManagement.yardCollapse != 2 && $store.state.yardManagement.yardCollapse != 3">
      <div class="Containers-header p-2">
        <div class="text-white col-sm-12 col-lg-12">
          <CRow>
            <CCol sm="12" lg="2" >
              <label class="col-form-label col-sm-12 text-white mb-0">{{ $t('label.Containers') }}</label>
            </CCol>
            <CCol sm="12" lg="10">
              <CRow>
                <CCol sm="12" style="flex-wrap: nowrap;" :class="!this?.dataContainer?.HeaderJson ? 'col-lg-12 col-xl-8' : 'col-lg-7 col-xl-5' ">
                  <label class="col-form-label col-sm-12 col-lg-12 text-right text-white mb-0">{{$t('label.findContainersKey')}}</label>
                </CCol>
                <CCol sm="12"   class="d-flex row align-items-center justify-content-start" :class="!this?.dataContainer?.HeaderJson ? 'col-lg-6 col-xl-4' : 'col-lg-12 col-xl-7' ">
                  <div class="col-sm-10 col-lg-auto col-xl-auto input-group Containers-Search mr-2" style="flex-wrap: nowrap;" :class="this.dataContainer.HeaderJson && 'col-lg-6 col-xl-5'">
                    <input 
                      type="text"
                      class="form-control-Search"
                      v-model="ContainerSearch"
                      :placeholder="$t('label.EnterContainerCode')"
                      :maxlength="11"
                    >
                    <div class="input-group-append">
                      <div class="d-flex align-items-center">
                        <CButton
                          size="sm"
                          class="btn-light-watch"
                          style="padding: 0.15rem 0.4rem;"
                          @click="getVisitContainerSearch()" 
                          getPortActivityList
                        >
                          <CIcon name="cil-search"/>
                        </CButton>
                      </div>
                    </div>
                  </div>
                  <CSelect
                    v-if="this.dataContainer.HeaderJson && validateYardCargoStatus"
                    v-model="yardSelected"
                    @change="filterYard($event.target.value)"
                    :addInputClasses="'not-border'"
                    class="col-sm-auto col-lg-auto d-flex align-items-center px-2 py-0 mr-2 mb-0"
                    :options="YardsOptions"
                    :placeholder="$t('label.select')"
                  >
                  </CSelect>
                  <CButton
                    v-if="this.dataContainer.HeaderJson"
                    square
                    :disabled = "validateYardCargoStatus && yardSelected ==='' "
                    style="background-color: #e0e1e3; height: 35px;"
                    @click="generateTransaction()"
                    class="col-sm-auto d-flex align-items-center px-2 py-0 mr-2"
                    v-c-tooltip="{
                    content: $t('label.generateTransaction'),
                    placement: 'top-end'
                    }"
                  >
                      <CIcon name="exit" />
                  </CButton>
                  <CButton
                    v-if="this.dataContainer?.AlertsJson?.length>0"
                    square
                    style=" background-color: #e7ac2c; height: 35px;"
                    @click="openModalAlerts()"
                    class="col-sm-auto col-lg-auto d-flex align-items-center px-2 py-0"
                  >
                    <CIcon name="cil-warning"  class="text-white"/><span class="ml-1 text-white" style="font-size: 12px;">{{$t('label.alerts')}}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>  
      </div>
      <div>
        <ContainerInfo></ContainerInfo>
      </div>
      <div class="Containers-data" v-show="Object.keys(this.$store.state.containerSearch.dataContainer).length > 0">
        <CCardBody>
          <CustomTabs>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.bl')}}</span>
                </template>
                <BlList></BlList>
              </CustomTab>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.movementsInYard')}}</span>
                </template>
                <div class="mb-3">
                  <CTabs>
                    <CTab :title="$t('label.reception')">
                        <Reception></Reception>
                    </CTab>
                    <CTab :title="$t('label.dispatch')">
                        <Dispatch></Dispatch>
                    </CTab>   
                    <CTab :title="$t('label.containerPositioning')">
                        <ContainerPositioning></ContainerPositioning>
                    </CTab>  
                  </CTabs>
                </div>
              </CustomTab>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.eir')}}</span>
                </template>
                <Eir></Eir>
              </CustomTab>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.events')}}</span>
                </template>
                <Events></Events>
              </CustomTab>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.inspections')}}</span>
                </template>
                <Inpections></Inpections>
              </CustomTab>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.technicalAspects')}}</span>
                </template>
                <TechnicalAspect></TechnicalAspect>
              </CustomTab>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.transactionHistory')}}</span>
                </template>
                <Transactions></Transactions>
              </CustomTab>
              <CustomTab>
                <template #title>
                  <span>{{$t('label.documentation')}}</span>
                </template>
                <Documentation></Documentation>
              </CustomTab>
          </CustomTabs>
        </CCardBody>
      </div>
    </CCollapse>
    <CCollapse :show="$store.state.yardManagement.yardCollapse == 2">
      <Container v-if="$store.state.yardManagement.yardCollapse == 2"></Container>
    </CCollapse>
    <CCollapse :show="$store.state.yardManagement.yardCollapse == 3">
      <yardReceptionDetail v-if="$store.state.yardManagement.yardCollapse == 3"></yardReceptionDetail>
    </CCollapse>
    <AlertsModal
      :modal.sync="alertModal"
      @close="closeModal"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import ContainerInfo from './container-info';
import BlList from './bl/index-bl';
import Eir from './eir/index-eir';
import Events from './events/index-events';
import Inpections from './inspections/index-inspections';
import TechnicalAspect from './technicalAspect/index-technical-aspect';
import Transactions from './transactions/index-transactions';
import Documentation from './documentation/index-documentation';
import Reception from './movementsYard/index-reception';
import Dispatch from './movementsYard/index-dispatch';
import ContainerPositioning from './movementsYard/index-container-positioning';
import { mapState } from 'vuex';
import ENUM from '@/_store/enum';
import Container from '@/pages/yard-management/container/container-index';
import yardReceptionDetail from '@/pages/yard-management/yard-reception-detail';
import AlertsModal from './alerta-modal';


//data
function data() {
  return {
    ContainerSearch: '',
    alertModal: false,
    yardSelected: '',
    dataYard: [],
    setDataYard: []
  }
}

//methods

function YardList() {
  this.$store.state.yardManagement.loading = true;
  let YardUserJson = [{
    CompanyBranchId: this.branch.CompanyBranchId 
  }];

  this.$http.post("YardManagement-list", YardUserJson, { root: 'YardUserJson' })
  .then(response => {
      this.dataYard = response.data.data ? response.data.data : [];
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

function filterYard(event) {
  this.yardSelected = event;
}

function getVisitContainerSearch() {
 
  if(this.ContainerSearch == '')
    return;

  this.$store.state.containerSearch.loading = ENUM.LOADING;

  this.$http.get('VisitContainer-search', { ContainerCode: this.ContainerSearch })
  .then(response => {
    if(response.data.data) {
      this.$store.state.containerSearch.dataContainer = {};
      this.$store.state.containerSearch.containerOptions = {};
      this.yardSelected = '';
      
      if(response.data.data.length>0)
        this.$store.state.containerSearch.containerOptions = response.data.data;
      else
        throw this.$t('label.containerNotFount');
    }
      
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.$store.state.containerSearch.loading = ENUM.LOADED;
  });
}

function generateTransaction() {
    
  if(this.dataContainer.HeaderJson[0].YardId){
    this.$store.state.containerSearch.loading = ENUM.LOADING;
    let cargoId = this.dataContainer.HeaderJson[0].CargoId;

    this.$http.get('YardCargo-by-Id', { YardId: validateYardCargoStatus && this.yardSelected !='' ? this.yardSelected : this.dataContainer.HeaderJson[0].YardId , YardCargoId: this.dataContainer.HeaderJson[0].YardCargoId
    })
      .then(response => {
          let List = response.data.data;
          let container = List.filter((item) => {return item.CargoId === cargoId });

          if(container.length>0){
            this.$store.state.yardManagement.yardCollapse = 2;
            this.$store.state.yardManagement.dataContainer = container[0];
            this.$store.state.yardManagement.yardData = {YardId : validateYardCargoStatus && this.yardSelected !='' ? this.yardSelected : this.dataContainer.HeaderJson[0].YardId}
          }
          else{
            let yard = this.dataYard.filter((item) => item.YardId=== this.yardSelected)
            this.$store.state.yardManagement.yardData = yard[0];
            this.$store.state.yardManagement.yardCollapse = 3;
          }
          
      })
      .catch( err => {
          this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      })
      .finally(() => {
        this.$store.state.containerSearch.loading = ENUM.LOADED;
      });
  }else{
    let yard = this.dataYard.filter((item) => item.YardId=== this.yardSelected)
    this.$store.state.yardManagement.yardData = yard[0];
    this.$store.state.yardManagement.yardCollapse = 3;
  }
    
}
function openModalAlerts(){
  this.alertModal = true;
}

function closeModal(){
  this.alertModal = false;
}

//Computeds:
function YardsOptions() {
  if(this.dataYard?.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }] 

    this.dataYard?.map(function(e){
      chart.push({
        value: e.YardId,
        label: e.YardName,
      })
    })
    
    return chart;
  }
  
}

function validateYardCargoStatus() {
  return process.env.VUE_APP_YARD_CARGO_STATUS_SHIP_SIDE === this.dataContainer?.HeaderJson[0]?.YardCargoStatus;
}


export default {
  name: 'search-containers-index',
  components: {
    CustomTabs,
    CustomTab,
    ContainerInfo,
    BlList,
    Eir,
    Events,
    Inpections,
    TechnicalAspect,
    Transactions,
    Documentation,
    Reception,
    Dispatch,
    ContainerPositioning,
    Container,
    yardReceptionDetail,
    AlertsModal
   },
  data,
  mounted(){
    this.$store.state.yardManagement.yardCollapse == 0;
    this.YardList();
  },
  beforeDestroy () {
    this.$store.state.yardManagement.yardCollapse = 0;
    this.$store.state.yardManagement.dataContainer = {};
    this.$store.state.containerSearch.dataContainer = {};
    this.$store.state.yardManagement.loading= false;
    this.$store.state.yardManagement.yardData = {};
  },
  mixins: [GeneralMixin],
  methods: {
    getVisitContainerSearch,
    generateTransaction,
    closeModal,
    openModalAlerts,
    filterYard,
    YardList,
  },
  computed: {
    YardsOptions,
    validateYardCargoStatus,
    ...mapState({
      branch: state => state.auth.branch,
      config: state => state.auth.config,
      loading: state => state.containerSearch.loading,
      dataContainer: state => state.containerSearch.dataContainer,
      successReception: state => state.containerSearch.successReception,
      yardCollapse: state => state.yardManagement.yardCollapse,
    })
  },
  watch: {
    yardCollapse: async function(val){
      if (this.yardCollapse === val && this.successReception) {
        this.getVisitContainerSearch();
        this.$store.state.containerSearch.successReception = false;
      }
    },
  },
};
</script>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
.form-control-Search{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  outline: none;
}
.Containers-data{
  margin: 5px;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 10px;
}
</style>