<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>
    <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListContainer"
        :fields="positioningfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
    >

        <template #Status="{ item }">
            <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                </CBadge>
            </td>
        </template>
    </dataTableExtended>
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { DateFormater } from '@/_helpers/funciones';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import { mapState } from 'vuex';
    import ENUM from '@/_store/enum';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            ContainerItem:{},
            dataPositioning: [],
        };
    }


    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.positioningfields.map(item=>{   
            if (item.key!='options' && item.key!='pdf') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })

        for (let i = 0; i < this.dataPositioning.length; i++) {
            let YardNewDetailPosJson = this.dataPositioning[i]?.YardNewDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());
            
            let PreviousDetailPosJson = this.dataPositioning[i]?.YardPreviousDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());


            rowDataFormated.push({

                RowNumber: this.dataPositioning[i].IdX ?? 'N/A',
                EventName: (_lang=='en' ? this.dataPositioning[i].EventNameEn : this.dataPositioning[i].EventNameEs) ?? 'N/A',
                PreviousYardSlotCode: PreviousDetailPosJson?.join(', ') ?? 'N/A',
                YardSlotCode: YardNewDetailPosJson?.join(', ') ?? 'N/A',
                EventReasonName: (_lang=='en' ? this.dataPositioning[i].EventReasonNameEn : this.dataPositioning[i].EventReasonNameEs) ?? 'N/A', 
                EventName: _lang=='en' ? this.dataPositioning[i].EventNameEn : this.dataPositioning[i].EventNameEs, 
                DeviceCod: this.dataPositioning[i].DeviceCod ?? 'N/A',
                FormatedDate: this.dataPositioning[i].TransactionDate ? DateFormater.formatDateTimeWithSlash(this.dataPositioning[i].TransactionDate) : 'N/A',
                TransaLogin: this.dataPositioning[i].TransaLogin ?? 'N/A',
                Status: this.dataPositioning[i].Status ?? 'N/A',
            });   
        }
        if(this.nulo!="null" && this.dataPositioning.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.containerPositioning'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    function computedListContainer() {

        let _lang = this.$i18n.locale;
        let data = [];

        this.dataPositioning?.map(
        (item) => {
            let YardNewDetailPosJson = item.YardNewDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());
            
            let PreviousDetailPosJson = item.YardPreviousDetailPosJson?.filter((element) => { return element.YardSlotStatus.toUpperCase() === process.env.VUE_APP_CONTAINER_SEARCH_NEW_POSITION_STATUS_ID; }).map(element=> element.SlotCode.trim());
            
            data.push(
                {
                    RowNumber: item.IdX ?? 'N/A',
                    PreviousYardSlotCode: PreviousDetailPosJson?.join(', ') ?? 'N/A',
                    YardSlotCode: YardNewDetailPosJson?.join(', ') ?? 'N/A',
                    EventReasonName: (_lang=='en' ? item.EventReasonNameEn : item.EventReasonNameEs) ?? 'N/A', 
                    EventName: (_lang=='en' ? item.EventNameEn : item.EventNameEs) ?? 'N/A', 
                    DeviceCod: item.DeviceCod ?? 'N/A',
                    TransactionBegin: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : 'N/A',
                    TransactionFinish: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : 'N/A',
                    TransaLogin: item.TransaLogin ?? 'N/A',
                    Status: item.Status ?? 'N/A',
                    _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
                }
            )
        }
        );

        return  data
    }

    function Update() {
        this.ModalBlContainer = false;
    }

    function positioningfields(){
        return [
            { key: 'RowNumber', label: '#', _style: 'width:3%; text-align:center', _classes: 'text-center', filter: false },
            { label: this.$t('label.events'), key: 'EventName', _classes: 'text-uppercase text-center', _style: 'width:11%; text-align:center;', sorter: true, filter: true},
            { key: 'EventReasonName', label: this.$t('label.movementType'),  _classes: 'text-uppercase text-center', _style: 'width:16%; text-align:center;', sorter: true, filter: true},
            { key: 'PreviousYardSlotCode', label: this.$t('label.previousPosition'), _style:'width:16%; text-align:center;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
            { key: 'YardSlotCode', label: this.$t('label.newPosition'), _style:'width:18%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'DeviceCod', label: this.$t('label.device'),  _style:'width:15%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'TransactionBegin', label: this.$t('label.initialDate'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransactionFinish', label: this.$t('label.finalDate'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'width:12%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:9%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
        ];
    }

    

    
    function toggleContainerEdit(item) {
        this.ContainerItem = item;
        this.ModalBlContainer = !this.ModalBlContainer;
    }

    export default{
        name: 'index-container-positioning',
        data,
        mixins: [General, ReportSearchContainer],
        methods: {
            Update,
            toggleContainerEdit,
            onBtnExport
        },
        computed: {
            positioningfields,
            computedListContainer,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.MovementsInYardJson && this.$store.state.containerSearch.dataContainer?.MovementsInYardJson[0]?.PositioningJson){
                    this.dataPositioning = this.$store.state.containerSearch.dataContainer?.MovementsInYardJson[0].PositioningJson;
                }
                else
                    this.dataPositioning = [];
            },
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>