var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('CRow',{staticClass:"my-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"12","xl":"12"}},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"excel","size":"sm"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XSLX ")],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListDispatch,"fields":_vm.Dispatchfields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.eirTransaction'),
                  placement: 'top-end'
                  }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.eirTransaction'),\n                  placement: 'top-end'\n                  }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.toggleContainerEdit(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"pdf",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.eirTransaction'),
                  placement: 'top-end'
                  }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.eirTransaction'),\n                  placement: 'top-end'\n                  }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","size":"sm","color":"danger"},on:{"click":function($event){return _vm.toggleContainerEdit(item)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }