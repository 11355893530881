<template>
  <div class="mt-3">
    <CRow class="Container-items px-3">
        <CCol sm="12" lg="6" >
            <CRow class="Container-items justify-content-center">
                <CCol sm="12" lg="4">
                    <CInput
                        size="sm"
                        v-model="this.dataTechnical.ContainerCode" 
                        :disabled="true"
                        addLabelClasses="text-right text-label-blue"
                        :label="$t('label.containerAcronyms')"
                        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                        :placeholder="$t('label.containerAcronyms')"
                    >
                    </CInput>
                </CCol>
                <CCol sm="12" lg="4">
                    <CInput
                        size="sm"
                        v-model="this.TpCargoName"
                        :disabled="true"
                        addLabelClasses="text-right text-label-blue"
                        :label="$t('label.type')"
                        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                        :placeholder="$t('label.type')"
                    >
                    </CInput>
                </CCol>
                <CCol sm="12" lg="4">
                    <CInput
                        size="sm"
                        :value="this.methodFormated(this.dataTechnical.Size)"
                        :disabled="true"
                        addLabelClasses="text-right text-label-blue"
                        :label="$t('label.size')"
                        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                        :placeholder="$t('label.size')"
                    >
                    </CInput>
                </CCol>
            </CRow>

            <CRow class="Container-items justify-content-center">
                <CCol sm="12" lg="4">
                    <CInput
                        size="sm" 
                        :value="this.methodFormated(this.dataTechnical.WeigthEmpty)"
                        :disabled="true"
                        addLabelClasses="text-right text-label-blue"
                        :label="$t('label.emptyWeith')"
                        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                        :placeholder="$t('label.emptyWeith')"
                    >
                    </CInput>
                </CCol>
                <CCol sm="12" lg="4">
                    <CInput
                        size="sm"
                        :value="this.methodFormated(this.dataTechnical.MaxLoad)"  
                        :disabled="true"
                        addLabelClasses="text-right text-label-blue"
                        :label="$t('label.maximumLoad')"
                        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                        :placeholder="$t('label.maximumLoad')"
                    >
                    </CInput>
                </CCol>
                <CCol sm="12" lg="4">
                    <CInput
                        size="sm" 
                        :value="this.methodFormated(this.dataTechnical.MaxGrossWeigth)" 
                        :disabled="true"
                        addLabelClasses="text-right text-label-blue"
                        :label="$t('label.maximunGrossWeight')"
                        :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                        :placeholder="$t('label.maximunGrossWeight')"
                    >
                    </CInput>
                </CCol>
            </CRow>
        </CCol>
        <CCol sm="12" lg="6">
            <CTextarea
                size="sm" 
                :value="this.dataTechnical.TpCargoDs" 
                :disabled="true"
                :label="$t('label.description')"
                addLabelClasses="text-right text-label-blue"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.description')"
                rows="5"
            />
        </CCol>
    </CRow>


    <CRow class="Container-items px-3 justify-content-center">
        <CCol sm="12" lg="2">
            <CInput
                size="sm"
                :value="this.methodFormated(this.dataTechnical.OutsideHeigth)" 
                :disabled="true"
                :label="$t('label.highOutside')"
                addLabelClasses="text-right text-label-blue"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.highOutside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2">
            <CInput
                size="sm" 
                :value="this.methodFormated(this.dataTechnical.OutsideWidth)" 
                :disabled="true"
                :label="$t('label.widthOutside')"
                addLabelClasses="text-right text-label-blue"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.widthOutside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2">
            <CInput
                size="sm"
                :value="this.methodFormated(this.dataTechnical.InsideHeigth)" 
                :disabled="true"
                :label="$t('label.highInside')"
                addLabelClasses="text-right text-label-blue"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.highInside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2">
            <CInput
                size="sm"
                :value="this.methodFormated(this.dataTechnical.OutsideWidth)" 
                :disabled="true"
                :label="$t('label.widthInside')"
                addLabelClasses="text-right text-label-blue"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.widthInside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2" >
            <CInput
                size="sm"
                v-model="this.FgSpecial" 
                :disabled="true"
                addLabelClasses="text-right text-label-blue"
                :label="$t('label.special')"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.special')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2"></CCol>
    </CRow>

    <CRow class="Container-items px-3 justify-content-center">
        
        <CCol sm="12" lg="2">
            <CInput 
                size="sm" 
                :value="this.methodFormated(this.dataTechnical.OutsideLength)" 
                :disabled="true"
                addLabelClasses="text-right text-label-blue"
                :label="$t('label.lengthOutside')"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.lengthOutside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2">
            <CInput
                size="sm"
                :value="this.methodFormated(this.dataTechnical.OutsideVolumen)" 
                :disabled="true"
                addLabelClasses="text-right text-label-blue"
                :label="$t('label.volumeOutside')"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.volumeOutside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2">
            <CInput
                size="sm"
                :value="this.methodFormated(this.dataTechnical.InsideLength)" 
                :disabled="true"
                addLabelClasses="text-right text-label-blue"
                :label="$t('label.lengthInside')"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.lengthInside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2">
            <CInput
                size="sm"
                :value="this.methodFormated(this.dataTechnical.InsideVolumen)" 
                :disabled="true"
                addLabelClasses="text-right text-label-blue"
                :label="$t('label.volumeInside')"
                :horizontal="{label:'col-sm-12 col-lg-auto', input:'col-sm-12 col-lg-12'}"
                :placeholder="$t('label.volumeInside')"
            >
            </CInput>
        </CCol>
        <CCol sm="12" lg="2">
        </CCol>
        <CCol sm="12" lg="2">
        </CCol>
    </CRow>

    
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { NumberFormater } from '@/_helpers/funciones';
    import { mapState } from 'vuex';

    //data
    function data() {
        return {
            dataTechnical: [],
            TpCargoName: '',
            FgSpecial: '',
        };
    }

    function methodFormated(item) {
        if(item)
            return  NumberFormater.formatNumber(item, 2) ?? 0
        else
            return  0
    }

    export default{
        name: 'technical-aspect',
        data,
        mixins: [General],
        components: {
        },
        methods: {
            methodFormated
        },
        computed: {
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                let _lang = this.$i18n.locale;

                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.TechnicalAspectsJson){
                    this.dataTechnical = this.$store.state.containerSearch.dataContainer?.TechnicalAspectsJson[0];
                    this.TpCargoName =   _lang=='en'  ? this.dataTechnical.TpCargoNameEn : this.dataTechnical.TpCargoNameEs;
                    this.FgSpecial =  this.dataTechnical.FgSpecial? this.$t('label.yes') : this.$t('label.no');
                }
                else
                    this.dataTechnical = [];
                
            },
        }
    }
</script>
<style>
    .text-label-blue{
        color: #274267;
        font-weight: bold;
    }
</style>